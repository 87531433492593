//
// Root
//

// Light mode
@include theme-light() {
    // Page bg color
    --kt-page-bg: #{$page-bg};

    // Header
    --kt-header-default-bg-color: #{get($header-config, default, bg-color)};
	--kt-header-fixed-bg-color: #{get($header-config, fixed, bg-color)};
	--kt-header-fixed-box-shadow: #{get($header-config, fixed, box-shadow)};

	// Aside
	--kt-aside-box-shadow: #{get($aside-config, box-shadow)};
	--kt-aside-bg-color: #{get($aside-config, bg-color)};
	--kt-aside-minimized-hover-box-shadow: #{get($aside-config, minimized-hover-box-shadow)};

	// Toolbar
	--kt-toolbar-bg-color: #{get($toolbar-config, bg-color)};
	--kt-toolbar-box-shadow: #{get($toolbar-config, box-shadow)};
	--kt-toolbar-border-top: #{get($toolbar-config, border-top)};

	// Footer
	--kt-footer-bg-color: #{get($footer-config, bg-color)};
}

// Dark mode
@include theme-dark() {
    // Page bg color
    --kt-page-bg: #{$page-bg-dark};

    // Header
    --kt-header-default-bg-color: #{get($header-config, default, bg-color-dark)};
	--kt-header-fixed-bg-color: #{get($header-config, fixed, bg-color-dark)};
	--kt-header-fixed-box-shadow: #{get($header-config, fixed, box-shadow-dark)};

	// Aside
	--kt-aside-box-shadow: #{get($aside-config, box-shadow-dark)};
	--kt-aside-bg-color: #{get($aside-config, bg-color-dark)};
	--kt-aside-minimized-hover-box-shadow: #{get($aside-config, minimized-hover-box-shadow-dark)};

	// Toolbar
	--kt-toolbar-bg-color: #{get($toolbar-config, bg-color-dark)};
	--kt-toolbar-box-shadow: #{get($toolbar-config, box-shadow-dark)};
	--kt-toolbar-border-top: #{get($toolbar-config, border-top-dark)};

	// Footer
	--kt-footer-bg-color: #{get($footer-config, bg-color-dark)};
}

.preloader {
	position: relative;
	display: inline-table;
	width: 32px;
	height: 32px;
	background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
}